<template>
  <div class="zt-page-content">
    <div class="header">
      <div class="search-bar flex flex-align-center">
        <div style="width: 100px; font-size: 18px; color: rgba(41, 41, 41, 1)">
          <b>需求订单</b>
        </div>
        <!-- <el-input
          v-model="query.key"
          placeholder="对方公司/需求标题/订单编号"
          style="width: 500px"
        >
          <el-button
            @click="search()"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input> -->
      </div>
      <div class="tab_menu flex">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          @click="tab(index)"
          class="menu_item"
          :class="{ cur: tabIndex == index }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="zt-block search-query">
      <el-form :model="query" inline style="height: 40px">
        <el-form-item label="交货日期">
          <el-date-picker
            v-model="query.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 380px"
            @change="handleChange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select
            v-model="query.orderState"
            clearable
            placeholder="全部"
            @change="handleChange"
          >
            <el-option
              v-for="item in orderStateOption"
              :key="item.optionCode"
              :label="item.optionValue"
              :value="item.optionCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <BuyerOrder
        v-if="tabIndex == 0 && hackReset"
        :query="query"
        style="height: 100%"
      ></BuyerOrder>
      <DemandCustomize
        v-if="tabIndex == 1 && hackReset"
        :query="query"
        style="height: 100%"
      ></DemandCustomize>
    </div>
  </div>
</template>

<script>
import BuyerOrder from "./BuyerOrder"; // 打样订单
import DemandCustomize from "./DemandCustomize"; // 改款订单
export default {
  components: {
    BuyerOrder,
    DemandCustomize,
  },
  data() {
    return {
      orderStateOption: [
        {
          optionCode: -1,
          optionValue: "全部",
        },
        {
          optionCode: 0,
          optionValue: "等待我方签约",
        },
        {
          optionCode: 2,
          optionValue: "等待对方签约",
        },
        {
          optionCode: 4,
          optionValue: "等待我方付款",
        },
        {
          optionCode: 5,
          optionValue: "等待对方收款",
        },
        {
          optionCode: 6,
          optionValue: "交易完成",
        },
      ],
      query: {
        key: null,
        dateRange: null,
        orderState: -1, // 状态列表，0等待对方签约，2买方已签合同，4卖方已签合同，5买方已全部付款，6卖方全部确认收款
        page: 1,
        pagesize: 10,
      },
      tabList: [{ name: "打样订单" }, { name: "需求定制订单" }],
      tabIndex: 0,
      hackReset: true, //刷新子组件
      type: 0, // 作品类型 0单品作品，1套系作品
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.$route.query.type == 1) {
      this.tabIndex = 1;
    }
  },
  methods: {
    // 选项卡
    tab(index) {
      this.tabIndex = index;
      if (index == 1) {
        this.$router.push({ query: { type: 1 } });
      } else {
        this.$router.push({ query: { type: 0 } });
      }
      this.query = {
        key: null,
        dateRange: null,
        orderState: -1,
        page: 1,
        pagesize: 10,
      };
    },
    search() {
      this.hackReset = false;
      this.$nextTick(() => {
        this.hackReset = true;
      });
    },
    handleChange() {
      this.$router.push({
        query: {
          type: this.$route.query.type,
          key: this.query.k,
          style_no: this.query.style_no,
          stat: this.query.stat === -1 ? [0, 2, 4, 5, 6] : [this.query.stat],
          page: 1,
          pagesize: 10,
        },
      });
      this.hackReset = false;
      this.$nextTick(() => {
        this.hackReset = true;
      });
    },
  },
};
</script>
<style scoped lang="less">
.header {
  width: 100%;
  padding: 0 60px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #d1d9e2;
  .search-bar {
    padding: 20px 0 10px;
  }
  .tab_menu {
    .menu_item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: #4d4d4d;
      &.cur {
        color: #5074ee;
        border-bottom: 2px solid #5074ee;
      }
    }
  }
}
.select-wrap {
  margin-right: 30px;
  label {
    font-size: 14px;
    color: #292929;
    margin-right: 15px;
  }
  .head {
    font-size: 14px;
    color: #4d4d4d;
    line-height: 20px;
    > div {
      cursor: pointer;
      margin-right: 35px;
      span {
        &.active {
          color: #5074ee;
        }
      }
      img {
        width: 14px;
        height: 21px;
        margin-left: 6px;
      }
    }
  }
}
</style>